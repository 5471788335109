import { LiveTv } from "@material-ui/icons";
import { SWITCH_BASE_URL } from "constants/actionTypes";
import { AVATAR_AUTHENTICATION_WARNING } from "constants/actionTypes";
import { CLEAN_AVATAR_AUTHENTICATION_WARNING } from "constants/actionTypes";
import { SUCCESSFUL_CONNECTION } from "constants/actionTypes";
import { CONNECTION_TEST } from "constants/actionTypes";
import { SUCCESSFULL_AUTHENTICATION, LOGGIN_IN, FAILED_AUTHENTICATION, SUCCESSFULL_ACTIVE_USER, CLEAN_AUTHENTICATION} from "constants/actionTypes";
import { FILE_BASE_URL, INIT_BASE_URL } from 'constants/urlTypes';

const initialState = { 

  loginError: false,
  logginIn: false,
  connection_flag: false,
  base_url: INIT_BASE_URL,
  init_base_url: INIT_BASE_URL,
  backup_base_url: FILE_BASE_URL,
  avatar_authentication_warning: false,
  user_name: "",
  user_id: "",
  active_user: {
    id:"",
    first_name: "",
    last_name: "",
    language: "en",
    roles: [],
    evaluation:{},
    studentgroup:{
      group:{
        program:"option.program1",
        embassador:{
          first_name:"",
          last_name:""
        }
      }
    },
  }
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESSFULL_AUTHENTICATION:
            return Object.assign({}, state, {
              token: action.payload.token, 
            });
        
        case FAILED_AUTHENTICATION:
            return Object.assign({}, state, {
              loginError: true,
              logginIn: false
            });
        case LOGGIN_IN:
            return Object.assign({}, state, {
              logginIn: true
            });

        case CLEAN_AUTHENTICATION:
            return Object.assign({}, state, {
              loginError: false,
              logginIn: false,
            });

        case SUCCESSFULL_ACTIVE_USER:
            let user_data = action.payload.data;
            if(user_data.studentgroup === undefined){
              user_data.studentgroup = initialState.active_user.studentgroup;
            }
            return Object.assign({}, state, {
              active_user: user_data
            });

        case SWITCH_BASE_URL:
          return Object.assign({}, state, {
            base_url: FILE_BASE_URL
          });

        case CONNECTION_TEST:
          return Object.assign({}, state, {
            connection_flag: true
          });

        case SUCCESSFUL_CONNECTION:
          return Object.assign({}, state, {
            connection_flag: false
          });

        case AVATAR_AUTHENTICATION_WARNING:
          return Object.assign({}, state, {
            user_name: action.payload.user_name,
            user_id: action.payload.user_id,
            avatar_authentication_warning: true,
          });

        case CLEAN_AVATAR_AUTHENTICATION_WARNING:
          return Object.assign({}, state, {
            avatar_authentication_warning: false,
            
          });
        
    }   
    return state;
}